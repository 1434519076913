import React, { useEffect } from "react";
import { Container } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "../external-css/swiper-bundle.min.css";
import "../external-css/style.css";
import "../external-css/apps.js";
import "@material-design-icons/font";
import ReactGA from "react-ga4";
import NavigationBar from "../components/NavigationBar";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import ImageForSpecialOffer from "../images/lingo-ninja-special-offer.png";
import ImageForLingoNinjaSimpleProcess from "../images/lingo-ninja-simple-process.png";
import ImageForLingoNinjaStep1 from "../images/lingo-ninja-step-1-type-or-paste-text.png";
import ImageForLingoNinjaStep2 from "../images/lingo-ninja-step-2-select-language.png";
import ImageForLingoNinjaStep3 from "../images/lingo-ninja-step-3-select-artist.png";
import ImageForLingoNinjaStep4 from "../images/lingo-ninja-step-4-generate-or-download-voice-over.png";
import ImageForMarketingUseCase from "../images/lingo-ninja-marketing-use-case.png";
import ImageForTutorialUseCase from "../images/lingo-ninja-tutorial-use-case.png";
import ImageForEducationalUseCase from "../images/lingo-ninja-educational-use-case.png";
import ImageForTvAdvertisementUseCase from "../images/lingo-ninja-tv-advertisement.png";
import ImageForAudioBookUseCase from "../images/lingo-ninja-audio-book-use-case.png";
import ImageForECommerceUseCase from "../images/lingo-ninja-e-commerce-use-case.png";
import ImageForPodcastsUseCase from "../images/lingo-ninja-podcasts-use-case.png";
import ImageForVideoAdvertisementUseCase from "../images/lingo-ninja-video-advertisement.png";
import ImageForSupportingMultipleLanguages from "../images/lingo-ninja-supporting-multiple-languages.png";
import ImageForIntegrateVoiceOversWithVideoEditors from "../images/lingo-ninja-voice-overs-for-video-editing-softwares.png";
import ArrowTop from "../images/arrow-top.png";
import ArrowBottom from "../images/arrow-bottom.png";
import LingoNinjaLogo from "../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet-async";
import { useAuth } from "../services/AuthService.jsx";

export default function Landing() {
  const { authData } = useAuth();
  useEffect(() => {
    // Google Analytics
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  });

  useEffect(() => {
    if (!document.querySelector(".wplus_spdisclaimer")) {
      const newDiv = document.createElement("div");
      newDiv.className = "wplus_spdisclaimer";
      newDiv.style.paddingTop = "30px";
      newDiv.style.paddingLeft = "100px";
      newDiv.style.paddingRight = "100px";
      newDiv.style.color = "lightgrey";

      const script = document.createElement("script");
      script.src = "https://warriorplus.com/o2/disclaimer/fj0y3y";
      script.async = true;

      document.body.appendChild(newDiv);
      document.body.appendChild(script);
    }

    return () => {
      const existingDiv = document.querySelector(".wplus_spdisclaimer");
      if (existingDiv) {
        document.body.removeChild(existingDiv);
      }

      const existingScript = document.querySelector(
        `script[src="https://warriorplus.com/o2/disclaimer/fj0y3y"]`
      );
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, []);

  return (
    <Container className="landing-container">
      <Helmet>
        <title>Home | LingoNinja</title>
        <meta
          name="description"
          content="First 100% AI based Natural Text-to-Speech Voiceover Generator"
        />
        <link rel="canonical" href="/home" />
      </Helmet>
      <nav
        className="navbar navbar-expand-lg fixed-top navbar-custom sticky-dark"
        id="navbar-sticky"
      >
        <div className="container">
          <a className="logo text-uppercase" href="index-1.html">
            <img src={LingoNinjaLogo} className="logo-small" alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul
              className="navbar-nav mx-auto navbar-center"
              id="mySidenav"
            ></ul>
            <ul className="navbar-nav navbar-center">
              <li className="nav-item">
                {authData ? (
                  authData.role === "Admin" ? (
                    <a href="/users" className="nav-link">
                      Users
                    </a>
                  ) : (
                    <a href="/speech" className="nav-link">
                      Speech
                    </a>
                  )
                ) : (
                  <a href="/login" className="nav-link">
                    Login
                  </a>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <section className="hero-2" id="home">
        <div className="bg-overlay-img"></div>
        <div className="container">
          <div className="row align-items-center justify-content-center hero-content">
            {/* <div className="col-lg-12">
              <h1 className="hero-title fw-bold mb-4 display-5">
                Create Voice from Text{" "}
                <span className="text-primary">Instantly 100%</span>
              </h1>
              <button className="btn btn-lg btn-gradient-primary">
                Get Started Today
              </button>
            </div> */}

            {/* <div className="col-md-8 col-lg-5 offset-lg-1">
              <div className="hero-2-img mt-5 mt-lg-0">
                <img
                  src={Screenshot1}
                  alt=""
                  className="img-fluid rounded-lg"
                />
              </div>
            </div> */}
          </div>
          <div className="row justify-content-around mt-5">
            <h1 className="hero-title text-center fw-bold mb-4 display-5">
              Create Voice from Text{" "}
              <span className="text-primary">Instantly 100%</span>
            </h1>
            <p className="text-center">
              The Lingo Ninja emotion-based text-to-speech engine, combined with
              the features listed below, firmly establishes Lingo Ninja as the
              leading AI voice generator, unmatched in the market!
            </p>
            <div className="col-md-6 col-12">
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </div>
                <div class="flex-grow-1 ms-4">
                  <p class="text-muted">
                    <span class="text-dark fw-bold">Convert Text to Voice</span>
                  </p>
                </div>
              </div>
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </div>
                <div class="flex-grow-1 ms-4">
                  <p class="text-muted">
                    <span class="text-dark fw-bold">
                      Includes Both Male and Female Voices
                    </span>
                  </p>
                </div>
              </div>
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </div>
                <div class="flex-grow-1 ms-4">
                  <p class="text-muted">
                    <span class="text-dark fw-bold">
                      The only text-to-voice tool that incorporates natural
                      inflections
                    </span>
                  </p>
                </div>
              </div>
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </div>
                <div class="flex-grow-1 ms-4">
                  <p class="text-muted">
                    <span class="text-dark fw-bold">
                      More than 494 natural-sounding voices in which 259
                      female's and 235 male voice's included.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </div>
                <div class="flex-grow-1 ms-4">
                  <p class="text-muted">
                    <span class="text-dark fw-bold">
                      Read the text in three different styles: normal, joyful,
                      and serious
                    </span>
                  </p>
                </div>
              </div>
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </div>
                <div class="flex-grow-1 ms-4">
                  <p class="text-muted">
                    <span class="text-dark fw-bold">
                      Supports English and 149 additional languages
                    </span>
                  </p>
                </div>
              </div>
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </div>
                <div class="flex-grow-1 ms-4">
                  <p class="text-muted">
                    <span class="text-dark fw-bold">
                      Driven by Next-Generation AI Emotion-Based Text-to-Speech
                      Technology
                    </span>
                  </p>
                </div>
              </div>
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </div>
                <div class="flex-grow-1 ms-4">
                  <p class="text-muted">
                    <span class="text-dark fw-bold">
                      Compatible with all video creation software: Camtasia,
                      Adobe Premiere, iMovie, Audacity, and more.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="hero-2-img mt-5">
                <img
                  src={ImageForSpecialOffer}
                  alt="Lingo Ninja's Special Offer"
                  className="img-fluid rounded-lg"
                />
              </div>
              <div
                className="hero-2-img mt-5"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a href="https://warriorplus.com/o2/buy/fj0y3y/gj5mw7/f715b4">
                  <img src="https://warriorplus.com/o2/btn/cn150011000/fj0y3y/gj5mw7/416450" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hero-2 bg-dark text-white">
        <div className="container">
          <h1 className="text-center mb-5">
            Conventional Vs AI-Powered Lingo Ninja Voiceovers
          </h1>
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="card shadow mb-3">
                <div className="card-body">
                  <h3 className="mb-4">Conventional Voiceovers</h3>
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-4">
                      <p class="text-muted">
                        <span class="text-dark fw-bold">
                          Engaging voiceover professionals and freelancers.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-4">
                      <p class="text-muted">
                        <span class="text-dark fw-bold">
                          Typical completion time: one week
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-4">
                      <p class="text-muted">
                        <span class="text-dark fw-bold">
                          Editing afterward demands technical proficiency
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-4">
                      <p class="text-muted">
                        <span class="text-dark fw-bold">
                          Making changes to the voiceover necessitates
                          additional recordings, frequently incurring extra
                          charges.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="card shadow mb-3">
                <div className="card-body">
                  <h3 className="mb-4">Lingo Ninja AI-Powered Voiceovers</h3>
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-4">
                      <p class="text-muted">
                        <span class="text-dark fw-bold">
                          Available via an intuitive web-based platform
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-4">
                      <p class="text-muted">
                        <span class="text-dark fw-bold">
                          Typical duration for generating a voiceover: 1 minute
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-4">
                      <p class="text-muted">
                        <span class="text-dark fw-bold">
                          Intuitive interface, perfect for newcomers
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-4">
                      <p class="text-muted">
                        <span class="text-dark fw-bold">
                          Effortlessly modify your voiceover content anytime at
                          no additional cost
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section bg-light" id="features">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <img
                src={ImageForLingoNinjaSimpleProcess}
                alt="Lingo Ninja's Simple Process"
                class="img-fluid d-block mx-auto ms-lg-auto"
              />
            </div>
            <div class="col-lg-5 offset-lg-1">
              <h1 class="fs-38 mb-4 mt-4">
                Creating first AI Text-to-Speech Voiceover
              </h1>
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </div>
                <div class="flex-grow-1 ms-4">
                  <p class="text-muted">
                    <span class="text-dark fw-bold">
                      No more intermediaries!
                    </span>
                  </p>
                </div>
              </div>
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </div>
                <div class="flex-grow-1 ms-4">
                  <p class="text-muted">
                    <span class="text-dark fw-bold">
                      Bid farewell to unreliable freelancers!
                    </span>
                  </p>
                </div>
              </div>
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </div>
                <div class="flex-grow-1 ms-4">
                  <p class="text-muted">
                    <span class="text-dark fw-bold">
                      No learning curve or installations needed!
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section bg-dark">
        <div class="container">
          <div class="row justify-content-center mb-5">
            <div class="col-md-12 col-lg-12 text-center">
              <h2 class="subtitle text-white">
                Generating a voiceover in just{" "}
                <span class="fw-bold">4 clicks</span>
              </h2>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <div class="work-box px-lg-5 text-center mb-5 mb-lg-0">
                <img
                  src={ImageForLingoNinjaStep1}
                  alt="Lingo Ninja's Step 1: Type or Paste your text"
                  className="img-fluid rounded-lg step-image"
                />
                <h5 class="fw-semibold text-white">Step 1: Insert Your Text</h5>
                <p class="text-white">
                  Simply paste your text into our online text-to-voice tool. Our
                  AI text-to-audio converter will analyze your text and
                  automatically add the necessary punctuation to ensure the
                  speech sounds natural.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="work-box px-lg-5 text-center mb-5 mb-lg-0">
                <img
                  src={ImageForLingoNinjaStep2}
                  alt="Lingo Ninja's Step 2: Select supported language"
                  className="img-fluid rounded-lg step-image"
                />
                <h5 class="fw-semibold text-white">
                  Step 2: Select language from 149 languages
                </h5>
                <p class="text-white">
                  We provide a selection of more than 149 languages for you to
                  select. Most of languages support multiple artists and you
                  option to select the gender i.e. male, female and age i.e.
                  adult, young.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="work-box px-lg-5 text-center mb-5 mb-lg-0">
                <img
                  src={ImageForLingoNinjaStep3}
                  alt="Lingo Ninja's Step 3: Select supported artist"
                  className="img-fluid rounded-lg step-image"
                />
                <h5 class="fw-semibold text-white">
                  Step 3: Select an artist from 500+ voices
                </h5>
                <p class="text-white">
                  We provide a selection of more than 494 voices for you to
                  explore. You can preview each voice to identify the one that
                  best suits your requirements. Additionally, you have the
                  option to select the tone of the narration.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="work-box px-lg-5 text-center mb-5 mb-lg-0">
                <img
                  src={ImageForLingoNinjaStep4}
                  alt="Lingo Ninja's Step 4: Generate Preview or Download voiceover"
                  className="img-fluid rounded-lg step-image"
                />
                <h5 class="fw-semibold text-white">
                  Step 4: Generate Preview or Download VoiceOver
                </h5>
                <p class="text-white">
                  In under 5 seconds, your AI voiceover will be generated. You
                  can play the voiceover directly from Lingo Ninja to preview it
                  and decide if you want to try a different voice. Once you're
                  satisfied, simply DOWNLOAD your new voiceover and use it for
                  your projects.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-light">
        <div className="container">
          <h1 className="text-center">
            Discover Next Generation AI Text-to-Speech Engine
          </h1>
          <div className="row justify-content-around mt-5">
            <div className="col-md-5 col-12 mt-3 align-self-center">
              <h2>Marketing Videos</h2>
              <p>
                Lingo Ninja voiceovers offer the emotional, engaging
                performances you'd usually associate with a professional voice
                actor, but in significantly less time and at a lower cost.
                Utilizing emotion-based voices, lingo Ninja Text-to-Speech
                technology allows you to enthrall your audience, showcase
                features, generate interest, or lead customers through enticing
                options effortlessly with just a few clicks.
              </p>
            </div>
            <div className="col-md-5 col-12 mt-3 align-self-center">
              <img
                src={ImageForMarketingUseCase}
                alt="Lingo Ninja's Marketing use case"
                className="img-fluid mx-auto rounded-lg"
              />
            </div>
          </div>
          <div className="row justify-content-around mt-5">
            <div className="col-md-5 col-12 mt-3 align-self-center">
              <img
                src={ImageForTutorialUseCase}
                alt="Lingo Ninja's Tutorial use case"
                className="img-fluid mx-auto rounded-lg"
              />
            </div>
            <div className="col-md-5 col-12 mt-3 align-self-center">
              <h2>Tutorial Videos</h2>
              <p>
                Lingo Ninja enables product developers to easily create
                voiceovers for demos and instructional videos, aiding in
                training employees or introducing new customers. Whether it's
                improving user experience or explaining new features, demo and
                help videos play a vital role in product success.
              </p>
            </div>
          </div>
          <div className="row justify-content-around mt-5">
            <div className="col-md-5 col-12 mt-3 align-self-center">
              <h2>Educational Seasons</h2>
              <p>
                Lingo Ninja streamlines the process for educators to enrich
                their teaching techniques. Transform essential content into
                engaging videos, integrate voiceovers into slide presentations,
                offer convenient audio lessons for students, or provide
                translations in multiple languages for enhanced accessibility
              </p>
            </div>
            <div className="col-md-5 col-12 mt-3 align-self-center">
              <img
                src={ImageForEducationalUseCase}
                alt="Lingo Ninja's Educational use case"
                className="img-fluid mx-auto rounded-lg"
              />
            </div>
          </div>
          <div className="row justify-content-around mt-5">
            <div className="col-md-5 col-12 mt-3 align-self-center">
              <img
                src={ImageForTvAdvertisementUseCase}
                alt="Lingo Ninja's Advertisement use case"
                className="img-fluid mx-auto rounded-lg"
              />
            </div>
            <div className="col-md-5 col-12 mt-3 align-self-center">
              <h2>Television Advertisements</h2>
              <p>
                When you're ready to enhance your marketing tactics, TV
                commercials often stand out as a great choice. With Lingo Ninja,
                you can seamlessly integrate additional voiceover text into
                scenes without live actors, expanding your advertising options
                while staying within your budget. Suitable for both small
                enterprises and expansive corporate initiatives.
              </p>
            </div>
          </div>
          <div className="row justify-content-around mt-5">
            <div className="col-md-5 col-12 mt-3 align-self-center">
              <h2>Audiobook</h2>
              <p>
                Lingo Ninja empowers you to convert your written material into
                polished audiobooks for enjoyment at home, during travel, or on
                the go. Going beyond mere clarity and natural delivery, Lingo
                Ninja text-to-speech engine also imbues the voice with emotion,
                rendering it perfect for audiobook production.
              </p>
            </div>
            <div className="col-md-5 col-12 mt-3 align-self-center">
              <img
                src={ImageForAudioBookUseCase}
                alt="Lingo Ninja's Audio Book use case"
                className="img-fluid mx-auto rounded-lg"
              />
            </div>
          </div>
          <div className="row justify-content-around mt-5">
            <div className="col-md-5 col-12 mt-3 align-self-center">
              <img
                src={ImageForECommerceUseCase}
                alt=""
                className="img-fluid mx-auto rounded-lg"
              />
            </div>
            <div className="col-md-5 col-12 mt-3 align-self-center">
              <h2>E-commerce Videos</h2>
              <p>
                In E-Commerce, establishing a connection between your audience
                and the voice in your video is paramount. Whether you require
                youthful female voices, seasoned male voices, or voices with
                diverse accents, Lingo Ninja provides them all. Optimize your
                investment by captivating a wide range of audiences through
                customizable languages and voice tones.
              </p>
            </div>
          </div>
          <div className="row justify-content-around mt-5">
            <div className="col-md-5 col-12 mt-3 align-self-center">
              <h2>Podcasts</h2>
              <p>
                Roughly 60 percent of U.S. consumers tune in to podcasts daily.
                If you're seeking to enrich your podcast brand with personalized
                commercials, content, or sound clips, Lingo Ninga offers the
                extra assistance required to genuinely captivate your audience.
              </p>
            </div>
            <div className="col-md-5 col-12 mt-3 align-self-center">
              <img
                src={ImageForPodcastsUseCase}
                alt="Lingo Ninja's Podcast use case"
                className="img-fluid mx-auto rounded-lg"
              />
            </div>
          </div>
          <div className="row justify-content-around mt-5">
            <div className="col-md-5 col-12 mt-3 align-self-center">
              <img
                src={ImageForVideoAdvertisementUseCase}
                alt="Lingo Ninja's Video Advertisement use case"
                className="img-fluid mx-auto rounded-lg"
              />
            </div>
            <div className="col-md-5 col-12 mt-3 align-self-center">
              <h2>Video Advertisements</h2>
              <p>
                If significant expenses have prevented you from conducting A/B
                tests on different voiceovers for your video ads, Revoicer is
                the answer you've been searching for. Explore different voices,
                accents, speeds, and emotions to uncover what connects most
                effectively with your audience, all without incurring any extra
                costs.
              </p>
            </div>
          </div>
          <div className="row justify-content-around mt-5">
            <div className="col-md-5 col-12 mt-3 align-self-center">
              <h2>Connecting Across Language Barriers</h2>
              <p>
                In our ever more interconnected world, the capacity to
                communicate across cultural and language differences is
                essential. Lingo Ninja, an advanced AI-powered text-to-speech
                platform, emerges as a crucial tool in this communication
                evolution. Tailored to serve a diverse global audience, Lingo
                Ninja provides effortless text-to-speech solutions in numerous
                languages, empowering content creators, businesses, and
                educators to broaden their impact and establish deeper
                connections with individuals around the globe.
              </p>
            </div>
            <div className="col-md-5 col-12 mt-3 align-self-center">
              <img
                src={ImageForSupportingMultipleLanguages}
                alt="Lingo Ninja's Support for Multiple Language"
                className="img-fluid mx-auto rounded-lg"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="hero-2 bg-dark text-white">
        <div className="container">
          <h1 className="mb-3">Experience Emotion in Text-to-Speech!</h1>
          <p className="mb-4">
            Traditionally, text-to-speech has been robotic, flat, and lacking in
            emotion... RIGHT? Not anymore with Lingo Ninja! Introducing the
            first AI Voice Generator that conveys authentic human emotions. Each
            generated voiceover embodies remarkably lifelike emotions, injecting
            vibrancy into your narrations. Lingo Ninja voices exhibit subtleties
            that render them AUTHENTIC, encompassing essential expressions and
            tonal variations to enthrall your audience and elevate interaction
            with your content.
          </p>
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="">
                <div className="">
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-4">
                      <p class="text-muted">
                        <span class="text-white fw-bold">Standard</span>
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-4">
                      <p class="text-muted">
                        <span class="text-white fw-bold">Amiable</span>
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-4">
                      <p class="text-muted">
                        <span class="text-white fw-bold">Optimistic</span>
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-4">
                      <p class="text-muted">
                        <span class="text-white fw-bold">Unwelcoming</span>
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-4">
                      <p class="text-muted">
                        <span class="text-white fw-bold">Joyful</span>
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-4">
                      <p class="text-muted">
                        <span class="text-white fw-bold">Melancholic</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="">
                <div className="">
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-4">
                      <p class="text-muted">
                        <span class="text-white fw-bold">Enthusiastic</span>
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-4">
                      <p class="text-muted">
                        <span class="text-white fw-bold">Indignant</span>
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-4">
                      <p class="text-muted">
                        <span class="text-white fw-bold">Fearful</span>
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-4">
                      <p class="text-muted">
                        <span class="text-white fw-bold">Loud</span>
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-4">
                      <p class="text-muted">
                        <span class="text-white fw-bold">Murmuring</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section bg-light" id="features">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <img
                src={ImageForIntegrateVoiceOversWithVideoEditors}
                alt=""
                class="img-fluid d-block mx-auto ms-lg-auto"
              />
            </div>
            <div class="col-lg-5 offset-lg-1">
              <h1 class="fs-38 mb-4 mt-4">
                Works with Every Video Editing Program
              </h1>
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <span class="avatar avatar-sm bg-white text-primary rounded-circle shadow-primary">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </div>
                <div class="flex-grow-1 ms-4">
                  <p class="text-muted">
                    <span class="text-dark fw-bold">
                      Lingo Ninja creates MP3 files that are universally
                      compatible with all video editing software.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div
          className="hero-2-img mt-5"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <a href="https://warriorplus.com/o2/buy/fj0y3y/gj5mw7/f715b4">
            <img src="https://warriorplus.com/o2/btn/cn150011000/fj0y3y/gj5mw7/416450" />
          </a>
        </div>
      </section>
    </Container>
  );
}
